import './app.css'

function App() {
  return (
    <div className='app'>
      <div>
        <img
          className='logo'
          alt='logo for motohoes.com'
          src='https://static.motohoes.com/motohoes-logo-zomg.svg'
        />
        <h1>Coming Soon</h1>
      </div>
    </div>
  )
}

export default App
